import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import ProjectItem from '../components/ProjectItem';

import site from '../data/site.json';

const DEFAULT_CLASSNAME: string = `projectspage`;
const HEADER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__header`;

const PROJECTSPAGECONTAINER: string = `${DEFAULT_CLASSNAME}__projectspagecontainer`;
const MAINCONTAINER_CLASSNAME: string = `${PROJECTSPAGECONTAINER}__maincontainer`;
const SUBHEADER_CLASSNAME: string = `${PROJECTSPAGECONTAINER}__subheader`;
const PERSONAL_CLASSNAME: string = `${PROJECTSPAGECONTAINER}__personal`;
const HEROKU_CLASSNAME: string = `${PROJECTSPAGECONTAINER}__heroku`;

const ProjectsPage = (): JSX.Element => {
  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={HEADER_CLASSNAME}>{site.projectsPage.mainheader}</div>

      <div className={PROJECTSPAGECONTAINER}>
        <div className={SUBHEADER_CLASSNAME}>{site.projectsPage.templates}</div>
        <div className={MAINCONTAINER_CLASSNAME}>
          <ProjectItem 
            image={<StaticImage src={'../images/micro.jpg'} alt={site.projectsPage.projects.EventDrivenAuthMicroservices.header}/>}
            header={site.projectsPage.projects.EventDrivenAuthMicroservices.header}
            technologies={site.projectsPage.projects.EventDrivenAuthMicroservices.technologies}
            githubLink={site.projectsPage.projects.EventDrivenAuthMicroservices.githublink}
            descriptionOne={site.projectsPage.projects.EventDrivenAuthMicroservices.detailsOne}
            descriptionTwo={site.projectsPage.projects.EventDrivenAuthMicroservices.detailsTwo}
          />
          <ProjectItem 
            image={<StaticImage src={'../images/realtime.jpg'} alt={site.projectsPage.projects.RealtimeGraphsTemplate.header}/>}
            header={site.projectsPage.projects.RealtimeGraphsTemplate.header}
            technologies={site.projectsPage.projects.RealtimeGraphsTemplate.technologies}
            githubLink={site.projectsPage.projects.RealtimeGraphsTemplate.githublink}
            descriptionOne={site.projectsPage.projects.RealtimeGraphsTemplate.detailsOne}
            descriptionTwo={site.projectsPage.projects.RealtimeGraphsTemplate.detailsTwo}
          />
        </div>

        <div className={SUBHEADER_CLASSNAME}>{site.projectsPage.newerProjects}</div>
        <div className={HEROKU_CLASSNAME}>{site.projectsPage.heroku}</div>
        <div className={MAINCONTAINER_CLASSNAME}>
          <ProjectItem 
            image={<StaticImage src={'../images/stopwatch.jpg'} alt={site.projectsPage.projects.StopwatchMania.header}/>}
            header={site.projectsPage.projects.StopwatchMania.header} 
            technologies={site.projectsPage.projects.StopwatchMania.technologies} 
            projectLink={site.projectsPage.projects.StopwatchMania.projectlink} 
            githubLink={site.projectsPage.projects.StopwatchMania.githublink}
            descriptionOne={site.projectsPage.projects.StopwatchMania.detailsOne}
            descriptionTwo={site.projectsPage.projects.StopwatchMania.detailsTwo}
          />
          <ProjectItem 
            image={<StaticImage src={'../images/path.jpg'} alt={site.projectsPage.projects.DijkstraRouteFinder.header}/>}
            header={site.projectsPage.projects.DijkstraRouteFinder.header} 
            technologies={site.projectsPage.projects.DijkstraRouteFinder.technologies} 
            projectLink={site.projectsPage.projects.DijkstraRouteFinder.projectlink} 
            githubLink={site.projectsPage.projects.DijkstraRouteFinder.githublink}
            descriptionOne={site.projectsPage.projects.DijkstraRouteFinder.detailsOne}
            descriptionTwo={site.projectsPage.projects.DijkstraRouteFinder.detailsTwo}
          />
        </div>

          
        <div className={SUBHEADER_CLASSNAME}>{site.projectsPage.personal}</div>
        <div className={PERSONAL_CLASSNAME}>{site.projectsPage.personalOne}</div>
        <div className={HEROKU_CLASSNAME}>{site.projectsPage.heroku}</div>
        <div className={MAINCONTAINER_CLASSNAME}>
          <ProjectItem 
            image={<StaticImage src={'../images/webdash.jpg'} alt={site.projectsPage.projects.CMT.header}/>}
            header={site.projectsPage.projects.CMT.header} 
            technologies={site.projectsPage.projects.CMT.technologies} 
            projectLink={site.projectsPage.projects.CMT.projectlink} 
            githubLink={site.projectsPage.projects.CMT.githublink}
            descriptionOne={site.projectsPage.projects.CMT.detailsOne}
            descriptionTwo={site.projectsPage.projects.CMT.detailsTwo}
          />
          <ProjectItem 
            image={<StaticImage src={'../images/artra.jpg'} alt={site.projectsPage.projects.Artra.header}/>}
            header={site.projectsPage.projects.Artra.header} 
            technologies={site.projectsPage.projects.Artra.technologies} 
            projectLink={site.projectsPage.projects.Artra.projectlink} 
            githubLink={site.projectsPage.projects.Artra.githublink}
            descriptionOne={site.projectsPage.projects.Artra.detailsOne}
            descriptionTwo={site.projectsPage.projects.Artra.detailsTwo}
          />
          <ProjectItem 
            image={<StaticImage src={'../images/cc.jpg'} alt={site.projectsPage.projects.CulinaryCatalogue.header}/>}
            header={site.projectsPage.projects.CulinaryCatalogue.header} 
            technologies={site.projectsPage.projects.CulinaryCatalogue.technologies} 
            projectLink={site.projectsPage.projects.CulinaryCatalogue.projectlink} 
            githubLink={site.projectsPage.projects.CulinaryCatalogue.githublink}
            descriptionOne={site.projectsPage.projects.CulinaryCatalogue.detailsOne}
            descriptionTwo={site.projectsPage.projects.CulinaryCatalogue.detailsTwo}
          />
          <ProjectItem 
            image={<StaticImage src={'../images/webdev.jpg'} alt={site.projectsPage.projects.WebDev.header}/>}
            header={site.projectsPage.projects.WebDev.header} 
            technologies={site.projectsPage.projects.WebDev.technologies} 
            projectLink={site.projectsPage.projects.WebDev.projectlink} 
            githubLink={site.projectsPage.projects.WebDev.githublink}
            descriptionOne={site.projectsPage.projects.WebDev.detailsOne}
            descriptionTwo={site.projectsPage.projects.WebDev.detailsTwo}
          />
          <ProjectItem 
            image={<StaticImage src={'../images/musicianlink.jpg'} alt={site.projectsPage.projects.MusicianLink.header}/>}
            header={site.projectsPage.projects.MusicianLink.header} 
            technologies={site.projectsPage.projects.MusicianLink.technologies} 
            projectLink={site.projectsPage.projects.MusicianLink.projectlink} 
            githubLink={site.projectsPage.projects.MusicianLink.githublink}
            descriptionOne={site.projectsPage.projects.MusicianLink.detailsOne}
            descriptionTwo={site.projectsPage.projects.MusicianLink.detailsTwo}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage