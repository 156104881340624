import React, { 
    useState, 
    ReactElement 
} from 'react';

import site from '../data/site.json';

interface IStaticImageProps {
    src: string;
    alt: string;
}

interface IProjectItemProps {
    image: ReactElement<IStaticImageProps, any>;
    header: string;
    technologies: string[];
    projectLink?: string,
    githubLink: string,
    descriptionOne: string;
    descriptionTwo: string;
}

const DEFAULT_CLASSNAME: string = `projectitem`;
const PROJECTITEMCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__projectitemcontainer`;
const IMAGECONTAINER_CLASSNAME: string = `${PROJECTITEMCONTAINER_CLASSNAME}__imagecontainer`;
const HEADER_CLASSNAME: string = `${PROJECTITEMCONTAINER_CLASSNAME}__header`;

const TECHNOLOGIESCONTAINER_CLASSNAME: string = `${PROJECTITEMCONTAINER_CLASSNAME}__technologiescontainer`;
const TECHNOLOGY_CLASSNAME: string = `${TECHNOLOGIESCONTAINER_CLASSNAME}__technology`;

const PROJECTLINKBUTTON_CLASSNAME: string = `${PROJECTITEMCONTAINER_CLASSNAME}__projectlinkbutton`;
const PROJECTLINK_CLASSNAME: string = `${PROJECTLINKBUTTON_CLASSNAME}__projectlink`;
const GITHUBLINKBUTTON_CLASSNAME: string = `${PROJECTITEMCONTAINER_CLASSNAME}__githublinkbutton`;
const GITHUBLINK_CLASSNAME: string = `${GITHUBLINKBUTTON_CLASSNAME}__githublink`;

const DESCRIPTIONCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__descriptioncontainer`;

const PROJECTITEMCONTAINERABS_CLASSNAME: string = `${DEFAULT_CLASSNAME}__projectitemcontainerabs`;
const DESCRIPTION_CLASSNAME: string = `${PROJECTITEMCONTAINERABS_CLASSNAME}__description`;

const ProjectItem = (props: IProjectItemProps): JSX.Element => {
    const { 
        image, 
        header,
        technologies,
        projectLink,
        githubLink,
        descriptionOne,
        descriptionTwo
    } = props;

    const [hover, setHover] = useState(false);

    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={PROJECTITEMCONTAINERABS_CLASSNAME}
                style={{height: hover ? '400px' : '0px', transition: 'height 0.3s'}} 
            >
                <div className={DESCRIPTION_CLASSNAME}>{descriptionOne}</div>
                <div className={DESCRIPTION_CLASSNAME}>{descriptionTwo}</div>
            </div>
            <div className={PROJECTITEMCONTAINER_CLASSNAME}>
                <div className={IMAGECONTAINER_CLASSNAME}>
                    {image}
                </div>
                <div className={HEADER_CLASSNAME}>{header}</div>
                <div className={TECHNOLOGIESCONTAINER_CLASSNAME}>
                    {technologies.map((item: string) => {
                        return (
                            <li 
                            className={TECHNOLOGY_CLASSNAME} 
                            style={{
                                color: item === site.projectsPage.projects.Artra.technologies[3] ? 'red' : 'black',
                                fontWeight: item === site.projectsPage.projects.Artra.technologies[3] ? 800 : 400
                                }}>
                                {item}
                            </li>
                        );
                    })}
                </div>
                {projectLink && <a className={PROJECTLINKBUTTON_CLASSNAME}
                    target={projectLink}
                    href={projectLink}>
                    <button className={PROJECTLINK_CLASSNAME}>Project</button>
                </a>}
                <a className={GITHUBLINKBUTTON_CLASSNAME}
                    target={githubLink}
                    href={githubLink}>
                    <button className={GITHUBLINK_CLASSNAME}>Github</button>
                </a>
            </div>
            <div className={DESCRIPTIONCONTAINER_CLASSNAME}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                DETAILS
            </div>
        </div>
    );
}

export default ProjectItem;